import * as React from "react";
import { Layout } from "../components/common/Layout";
import { SectionContainer } from "../components/common/SectionContainer";
import { Typography, Button, Stack } from "@mui/material";
import { Link } from "gatsby";

export default function NotFoundPage() {
  return (
    <Layout>
      <SectionContainer vCentre>
        <Typography
          variant="h1"
          sx={{ fontSize: "128px", textAlign: "center", fontWeight: "bold" }}
        >
          404
        </Typography>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", marginTop: "24px" }}
        >
          There seems to be something missing here...
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          alignSelf="center"
          sx={{ marginTop: "80px" }}
        >
          <Button
            variant="contained"
            sx={{ fontWeight: "bold" }}
            component={Link}
            to="/"
          >
            back home
          </Button>
        </Stack>
      </SectionContainer>
    </Layout>
  );
}
